import {Dictionary} from '../types'
import {useRecoilValue} from 'recoil'
import {clientState} from '../state'
import {LANG_DEFAULT} from '../config'

function useTranslate({clientData}: {clientData?: any} = {}) {
  const client = useRecoilValue(clientState)

  const local = client?.translations?.[LANG_DEFAULT] || {}

  const dictionary: Dictionary = {...require('../languages/se.json'), ...local}

  function escapeRegExp(input: string = '') {
    return (input || '').replace(/([.*+?^${}()|[\]/\\])/g, '\\$1')
  }

  function reduceTemplate(
    this: Record<string, Dictionary>,
    template: string,
    key: string,
  ) {
    return template.replace(
      new RegExp('{\\s*' + escapeRegExp(key) + '\\s*}', 'g'),
      this.data[key],
    )
  }

  function translate(template: string, data?: Dictionary) {
    data = {...clientData, ...data}

    if (!data) {
      return template
    }

    return Object.keys(data).reduce(
      reduceTemplate.bind({
        data: {
          ...clientData,
          ...data,
        },
      }),
      template,
    )
  }

  const t = (key: string, data?: Record<string, any>) => {
    return translate(dictionary[key] || key, data)
  }

  return {
    t,
  }
}

export default useTranslate
