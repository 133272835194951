/** @jsx jsx */
import {jsx} from '@emotion/core'

import Logo from '../../components/logo'
import Section from '../../components/section'
import {Checkbox, Form, Typography} from 'antd'
import DefaultButton from '../../components/default-button'
import {Fragment, useEffect, useState} from 'react'
import {navigate} from '@reach/router'
import useTranslate from '../../hooks/use-translate'
import {CheckboxChangeEvent} from 'antd/lib/checkbox'
import {cancelBooking, getReservationInfo} from '../../api/backend-client'
import moment from 'moment'
import capitalize from '../../utils'
import {useRecoilValue} from 'recoil'
import {clientState} from 'src/state'

const getCheckboxText = (reservationInfo: any) => {
  const {startTime, organizationName} = reservationInfo
  const dayName = moment.utc(startTime).format('dddd')
  const dayNameFormatted = capitalize(dayName)
  const formattedDate = moment.utc(startTime).format('D MMMM')
  const formattedTime = moment.utc(startTime).local(false).format('HH:mm')
  return `${dayNameFormatted} ${formattedDate}, kl ${formattedTime} på ${organizationName}.`
}

const ScreenCancel = ({...props}) => {
  const bookingId = props.bookingId
  const [agree, setAgree] = useState(false)
  const [error, setError] = useState(false)

  const [cancelInfo, setCancelInfo] = useState()

  const client = useRecoilValue(clientState)

  const {t} = useTranslate({
    clientData: {
      ...client,
    },
  })

  const onSubmit = () => {
    cancelBooking(bookingId)
      .then(response => {
        if (response.success) {
          navigate('/cancelled')
        } else {
          setError(true)
        }
      })
      .catch(() => {
        setError(true)
      })
  }

  useEffect(() => {
    getReservationInfo(bookingId)
      .then((response: any) => {
        setCancelInfo(response)
      })
      .catch((error: Error) => {
        setError(true)
      })
  }, [bookingId])

  return (
    <Form onFinish={onSubmit} layout="vertical" autoComplete="off">
      <Section>
        <Logo />
      </Section>
      <Section>
        <Typography.Title>{t('SCREEN_CANCEL_TITLE')}</Typography.Title>
        <div className="paragraph">{t('SCREEN_CANCEL_LINE1')}</div>
        <div className="paragraph">{t('SCREEN_CANCEL_LINE2')}</div>
      </Section>
      <Section>
        {cancelInfo && (
          <Fragment>
            <Form.Item
              name="agree"
              valuePropName="checked"
              rules={[{required: true}]}
            >
              <Checkbox
                onChange={(e: CheckboxChangeEvent) =>
                  setAgree(e.target.checked)
                }
              >
                <strong>{getCheckboxText(cancelInfo)}</strong>
              </Checkbox>
            </Form.Item>
            <Form.Item>
              <DefaultButton htmlType="submit" size="large" disabled={!agree}>
                {t('SCREEN_CANCEL_BUTTON_NAME')}
              </DefaultButton>
            </Form.Item>
          </Fragment>
        )}

        {error && (
          <Form.Item>
            <div className="messageError">{t('SCREEN_CANCEL_ERROR_24')}</div>
          </Form.Item>
        )}
      </Section>
    </Form>
  )
}

export default ScreenCancel
