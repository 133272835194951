/** @jsx jsx */
import {jsx} from '@emotion/core'
import * as style from './default-button.style'
import {Button} from 'antd'
import {ReactNode} from 'react'
import {ButtonProps} from 'antd/lib/button'
import {RightOutlined} from '@ant-design/icons'

type DefaultButtonAction = 'GO_HOME'

export interface DefaultButtonProps extends ButtonProps {
  children: ReactNode
  showArrow?: boolean
  action?: DefaultButtonAction
}

const DefaultButton = ({
  children,
  showArrow = false,
  action,
  ...props
}: DefaultButtonProps) => {
  const goHome = () => {
    window.localStorage.removeItem('kindBookingAppState')
    window.location.href = '/'
  }

  const callInternalFunction = () => {
    if (action) {
      switch (action) {
        case 'GO_HOME': {
          return goHome()
        }
        default:
          return console.warn(`Button action '${action}' not support`)
      }
    }
  }

  return (
    <Button
      css={style.defaultButtonStyle}
      onClick={callInternalFunction}
      {...props}
    >
      {children}
      {showArrow && <RightOutlined css={style.iconStyle} />}
    </Button>
  )
}

export default DefaultButton
