/** @jsx jsx */
import {jsx} from '@emotion/core'
import {Button, Result} from 'antd'
import * as style from './not-found.style'
import {navigate} from '@reach/router'
import KindLogo from '../assets/kind-logo.svg'

// TODO: Improve translations

function NotFound() {
  return (
    <div css={style.page}>
      <Result
        icon={<img src={KindLogo} alt="Booking by KIND" />}
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button type="primary" onClick={() => navigate('/')}>
            Back Home
          </Button>
        }
      />
    </div>
  )
}

export default NotFound
