/** @jsx jsx */
import {jsx} from '@emotion/core'
import * as style from './section.style'

const Section = ({children, type, ...props}: any) => {
  return (
    <div
      css={[style.section, type === 'wide' && style.sectionWide]}
      className="section"
      {...props}
    >
      {children}
    </div>
  )
}

export default Section
