/** @jsx jsx */
import {jsx} from '@emotion/core'

import Logo from '../../components/logo'
import Section from '../../components/section'
import {Col, Form, Input, Row, Select, Grid, Typography, Checkbox} from 'antd'

import {Fragment, useMemo, useState} from 'react'
import useScreen from '../../hooks/use-screen'
import Navigation from '../../components/navigation'
import {screenErrorState} from '../../state'
import DefaultButton from '../../components/default-button'
import {
  fetchOrganizations,
  getSlots,
  validatePromoCode,
} from '../../api/backend-client'
import moment, {Moment} from 'moment'
import {useRecoilState} from 'recoil'
import InlineErrorMessage from '../../components/inline-error-message'
import {renderErrorMessage} from '../../utils'
import {t} from '../../utils/translate'
import AppointmentPicker from '../../components/appointment-picker'
import {useMountEffect} from '../../hooks/use-mount-effect'

const {useBreakpoint} = Grid

const ScreenBook = () => {
  const {form, storedValue, isSaving, client, updateState} = useScreen()

  const screens = useBreakpoint()

  const {referenceCode, organizationId, promoCode, withoutAppointment} =
    storedValue

  const bookingAvailable = false

  const [screenError, setScreenError] = useRecoilState(screenErrorState)

  const [code, setCode] = useState('')
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<any>({
    cities: [],
    orgs: [],
  })

  const [slots, setSlots] = useState<any>([])

  const {promoCodesEnabled, displayMessageWhenNoBooking} = client

  const isDisabled = isSaving || loading

  const isValidPromoCode = Boolean(promoCode?.length)

  const getFirstAvailableTime = (): Moment => {
    const theoreticalStartingTime = moment().add(2, 'hours')
    return theoreticalStartingTime
  }

  const firstAvailableTime = useMemo(() => getFirstAvailableTime(), [])

  const firstAvailableDate = useMemo(
    () => moment(firstAvailableTime).startOf('day'),
    [firstAvailableTime],
  )
  const lastAvailableDate = useMemo(
    () => moment(firstAvailableDate).add(2, 'month').startOf('day'),
    [firstAvailableDate],
  )

  const onPromoCodeChange = (value: string) => {
    setCode(value)
  }

  const onSelectSlot = (value: any) => {
    form.setFieldsValue({startTime: value, withoutAppointment: false})
    form.validateFields()
    updateState(currentState => ({
      ...currentState,
      startTime: value,
      withoutAppointment: false,
    }))
  }

  const onWithoutAppointment = (value: any) => {
    form.setFieldsValue({withoutAppointment: value, startTime: ''})
    form.validateFields()
    updateState(currentState => ({
      ...currentState,
      withoutAppointment: value,
      startTime: '',
    }))
  }

  const onValidatePromoCode = () => {
    validatePromoCode(code)
      .then((response: any) => {
        updateState(currentState => ({...currentState, promoCode: code}))
      })
      .catch((error: Error) => {
        setScreenError(error.message)
      })
  }

  useMountEffect(() => {
    fetchOrganizations(referenceCode)
      .then((response: any) => {
        setData(response)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  })

  useMountEffect(() => {
    if (organizationId || promoCode) {
      getSlots(
        organizationId || '',
        firstAvailableTime.toISOString(),
        lastAvailableDate.toISOString(),
        referenceCode,
        promoCode,
      )
        .then((response: any) => {
          setSlots(response.slots || [])
        })
        .catch((error: Error) => {
          // pass
        })
    }
  })

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={storedValue}
      autoComplete="off"
    >
      <Section>
        <Logo />
      </Section>
      <Section>
        <div>
          {bookingAvailable
            ? t('SCREEN_BOOK_LINE1_WITH_BOOKING')
            : t('SCREEN_BOOK_LINE1_WITHOUT_BOOKING')}
        </div>
      </Section>
      <Section>
        <Typography.Title>
          {bookingAvailable
            ? t('SCREEN_BOOK_TITLE_WITH_BOOKING')
            : t('SCREEN_BOOK_TITLE_WITHOUT_BOOKING')}
        </Typography.Title>
        <Fragment>
          <Form.Item
            name="cityId"
            label={t('LABEL_SELECT_CITY')}
            className="formItem"
            rules={[
              {required: true, message: t('SCREEN_BOOK_CITY_ERROR_MESSAGE')},
            ]}
          >
            <Select
              size="large"
              disabled={isDisabled}
              loading={loading}
              placeholder={t('LABEL_SELECT_CITY')}
              onSelect={(_, option: any) => {
                updateState(currentState => ({
                  ...currentState,
                  cityId: option.value,
                  cityName: option.children,
                  organizationId: null,
                }))
                form.setFieldsValue({organizationId: null})
              }}
            >
              {data.cities.map((city: string) => (
                <Select.Option key={city} value={city}>
                  {city}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="organizationId"
            label={t('LABEL_SELECT_CLINIC')}
            className="formItem"
            rules={[
              {required: true, message: t('SCREEN_BOOK_CLINIC_ERROR_MESSAGE')},
            ]}
          >
            <Select
              size="large"
              defaultActiveFirstOption={false}
              disabled={isDisabled}
              loading={loading}
              placeholder={t('LABEL_SELECT_CLINIC')}
              onSelect={(_, option: any) =>
                updateState(currentState => ({
                  ...currentState,
                  organizationId: option.value,
                  organizationName: option.children,
                }))
              }
            >
              {data.orgs
                .filter((clinic: any) => clinic.city === storedValue.cityId)
                .map((clinic: any) => (
                  <Select.Option key={clinic.uuid} value={clinic.uuid}>
                    {clinic.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          {bookingAvailable && (
            <div css={{display: 'none'}}>
              <Form.Item
                name="startTime"
                label="Start Time"
                className="formItem"
                rules={[{required: !withoutAppointment && slots?.length}]}
              >
                <Input size="large" />
              </Form.Item>
            </div>
          )}

          {promoCodesEnabled && (
            <Row>
              <Col flex="auto">
                <Form.Item
                  name="promoCode"
                  label="Bokningskod"
                  className="formItem"
                  help={
                    screenError ? (
                      <InlineErrorMessage
                        message={renderErrorMessage(screenError, t)}
                      />
                    ) : (
                      isValidPromoCode && (
                        <div>{t('SCREEN_BOOK_PROMO_CODE_VALID_MESSAGE')}</div>
                      )
                    )
                  }
                >
                  <Input
                    size="large"
                    placeholder="Bokningskod"
                    disabled={isDisabled || isValidPromoCode}
                    onChange={e => onPromoCodeChange(e.target.value)}
                  />
                </Form.Item>
              </Col>
              {Boolean(code?.length) &&
                (screens.xs ? (
                  <Col flex="100%" className="horizontalPaddingTop">
                    <DefaultButton
                      block
                      size="large"
                      onClick={onValidatePromoCode}
                    >
                      {t('GENERIC_BUTTON_VALIDATE_CODE')}
                    </DefaultButton>
                  </Col>
                ) : (
                  <Col flex="none" className="horizontalPaddingLeft">
                    <DefaultButton size="large" onClick={onValidatePromoCode}>
                      {t('GENERIC_BUTTON_VALIDATE_CODE')}
                    </DefaultButton>
                  </Col>
                ))}
            </Row>
          )}

          {bookingAvailable ? (
            <Fragment>
              <Form.Item>
                <AppointmentPicker
                  setValue={updateState}
                  storedValue={storedValue}
                  firstAvailableDate={firstAvailableDate}
                  lastAvailableDate={lastAvailableDate}
                  onStepChange={() => {}}
                  onSelectSlot={onSelectSlot}
                  slots={slots}
                />
              </Form.Item>
              {Boolean(slots?.length) && (
                <div className="summaryBox">
                  <Form.Item name="withoutAppointment" valuePropName="checked">
                    <Checkbox
                      onChange={e => onWithoutAppointment(e.target.checked)}
                    >
                      {t('WITHOUT_APPOINTMENT_CHECKBOX')}
                    </Checkbox>
                  </Form.Item>
                </div>
              )}
            </Fragment>
          ) : (
            displayMessageWhenNoBooking && (
              <div className="summaryBox">
                <div>{t('NO_BOOKING_DESCRIPTION_LINE1')}</div>
                <div>{t('NO_BOOKING_DESCRIPTION_LINE2')}</div>
                <div>{t('NO_BOOKING_DESCRIPTION_LINE3')}</div>
              </div>
            )
          )}
        </Fragment>
      </Section>
      {screenError && (
        <Section>
          <div className="messageError">
            {renderErrorMessage(screenError, t)}
          </div>
        </Section>
      )}
      <Navigation form={form} />
    </Form>
  )
}

export default ScreenBook
