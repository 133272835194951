import React from 'react'
import {RecoilRoot} from 'recoil'

import LoadingSpinner from './components/loading-spinner'

import WebApp from './web-app'

function App() {
  return (
    <RecoilRoot>
      <React.Suspense
        fallback={
          <div>
            <LoadingSpinner />
          </div>
        }
      >
        <WebApp />
      </React.Suspense>
    </RecoilRoot>
  )
}

export default App
