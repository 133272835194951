/** @jsx jsx */
import {jsx} from '@emotion/core'
import {Upload, Form, Input} from 'antd'
import {DeleteOutlined, FileOutlined, InboxOutlined} from '@ant-design/icons'
import DefaultButton from '../default-button'
import useTranslate from '../../hooks/use-translate'
import {
  API_SERVICE_URL,
  FILE_UPLOAD_SIZE_LIMIT,
  FILE_UPLOAD_SIZE_LIMIT_TEXT,
} from '../../config'
import {Fragment, useState} from 'react'
import * as style from './file-uploader.style'
import {deleteUploadeFile} from '../../api/backend-client'
import InlineErrorMessage from '../inline-error-message'
import {truncateFileName} from '../../utils'
import {AppState, clientState} from '../../state'
import {useRecoilValue} from 'recoil'

const {Dragger} = Upload

const FileUploader = ({
  storedValue = {},
  updateState = (_: any) => {},
  form,
}: any) => {
  const client = useRecoilValue(clientState)

  const {t} = useTranslate({
    clientData: {
      ...client,
    },
  })

  const files = storedValue.files || []

  const [error, setError] = useState('')

  const beforeUpload = (file: any) => {
    const isLessThan = file.size / 1024 / 1024 < FILE_UPLOAD_SIZE_LIMIT
    if (!isLessThan) {
      setError(
        `Filen är för stor. Maximal filstorlek är ${FILE_UPLOAD_SIZE_LIMIT_TEXT}.`,
      )
    }
    return isLessThan
  }

  const props = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    action: `${API_SERVICE_URL}/patientFile`,
    accept: '.png,.jpg,.pdf',
    beforeUpload,
    onChange(info: any) {
      const {status, response} = info.file
      if (status === 'done') {
        const files = storedValue.files || []
        updateState((currentState: AppState) => ({
          ...currentState,
          files: [...files, {...response}],
        }))
        form.setFieldsValue({files: [...files, {...response}]})
        setError('')
      }
    },
  }

  const onDeleteFile = (fileId: any) => {
    deleteUploadeFile(fileId)
      .then(response => {
        const files = (storedValue.files || []).filter(
          (file: any) => file.fileId !== fileId,
        )
        updateState((currentState: AppState) => ({
          ...currentState,
          files: [...files],
        }))
        form.setFieldsValue({files: [...files]})
        setError('')
      })
      .catch(error => {
        // pass
      })
  }

  return (
    <Fragment>
      <div css={style.fileUploader}>
        <div className="hidden">
          <Form.Item
            label=""
            name="files"
            rules={[{required: true, message: 'Ladda upp minst en fil'}]}
          >
            <Input type="text" />
          </Form.Item>
        </div>
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">{t('FILE_UPLOAD_DRAG_MESSAGE')}</p>
          <div>
            <DefaultButton disabled={storedValue?.files?.length >= 5}>
              {t('FILE_UPLOAD_BUTTON')}
            </DefaultButton>
          </div>
        </Dragger>
      </div>

      {error && (
        <div className="messageError" css={style.fileUploaderError}>
          <InlineErrorMessage message={t(error)} />
        </div>
      )}

      <div css={style.fileUploaderList}>
        {files.map((file: any) => (
          <div key={file.fileId} css={style.fileUploaderListItem}>
            <div>
              <FileOutlined /> {truncateFileName(file.fileName, 30)}
            </div>
            <div>
              <div
                className="clickable"
                onClick={() => onDeleteFile(file.fileId)}
              >
                <DeleteOutlined />
              </div>
            </div>
          </div>
        ))}
      </div>
    </Fragment>
  )
}

export default FileUploader
