/** @jsx jsx */
import {jsx} from '@emotion/core'
import * as style from './navigation.style'
import {Row, Grid, Col, FormInstance} from 'antd'
import DefaultButton from '../default-button'
import {useRecoilValue} from 'recoil'
import {activeRoutesSelector, clientState} from '../../state'
import {Fragment} from 'react'
import {useLocation} from '@reach/router'
import {ArrowRightOutlined} from '@ant-design/icons'
import useTranslate from '../../hooks/use-translate'
import useScreen from '../../hooks/use-screen'

const {useBreakpoint} = Grid

interface NavigationPros {
  form?: FormInstance<any> | undefined
}

const Navigation = ({form}: NavigationPros) => {
  const screens = useBreakpoint()
  const location = useLocation()

  const activeRoutes = useRecoilValue(activeRoutesSelector)
  const client = useRecoilValue(clientState)

  const {t} = useTranslate({
    clientData: {
      ...client,
    },
  })

  const {handleNext, isSaving} = useScreen()

  const {pathname} = location

  const pagesWithBreadcrumbs = Object.keys(activeRoutes).reduce(
    (accumulator: any, path: any) => {
      const item = activeRoutes[path]
      if (item.presentingNavbar) {
        accumulator[path] = item
      }
      return accumulator
    },
    {},
  )

  const currentPage = activeRoutes[pathname]

  const onNext = () => {
    handleNext(form)
  }

  return (
    <Fragment>
      {currentPage.presentingRecaptchaMessage && (
        <div css={style.recaptchaMessage}>
          Denna webbplats är skyddad av reCAPTCHA och{' '}
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Googles Sekretesspolicy
          </a>{' '}
          och{' '}
          <a
            href="https://policies.google.com/terms"
            target="_blank"
            rel="noopener noreferrer"
          >
            Användarvillkor
          </a>{' '}
          gäller.
        </div>
      )}
      {currentPage.presentingNavbar && (
        <Row css={style.navigation}>
          {!screens.xs && (
            <Col flex="auto">
              <div css={style.breadcrumbs}>
                {Object.keys(pagesWithBreadcrumbs).map((path: any) => (
                  <Fragment key={path}>
                    <div css={pathname === path && style.activeItem}>
                      {t(pagesWithBreadcrumbs[path].breadcrumbName)}
                    </div>
                  </Fragment>
                ))}
              </div>
            </Col>
          )}

          <Col flex={screens.xs ? 'auto' : '250px'}>
            <DefaultButton
              size="large"
              className="ant-btn-min"
              css={screens.xs && style.fullWidth}
              onClick={onNext}
              disabled={isSaving}
              loading={isSaving}
            >
              {t('GENERIC_BUTTON_NEXT')} <ArrowRightOutlined />
            </DefaultButton>
          </Col>
        </Row>
      )}
    </Fragment>
  )
}

export default Navigation
