import {API_SERVICE_URL} from '../config'

interface ClientArgs {
  method?: string
  body?: BodyInit | any
  headers?: HeadersInit
}

const client = (endpoint: string, args: ClientArgs = {}) => {
  const {body, method} = args

  const parseUrl = new URL(window.location.pathname, window.location.href)

  let headers: HeadersInit = {
    'content-type': 'application/json',
    'x-kind-client-id': parseUrl.hostname,
  }

  if (args.headers) headers = {...headers, ...args.headers}

  const config: RequestInit = {
    method: method || (body ? 'POST' : 'GET'),
    headers,
  }

  if (body) {
    config.body = JSON.stringify(body)
  }

  console.log('DEBUG:API_SERVICE_URL', API_SERVICE_URL)

  return window
    .fetch(`${API_SERVICE_URL}/${endpoint}`, config)
    .then((response: Response) => {
      if (response.status !== 200) {
        return response.json().then((json: any) => {
          const {errorCode = ''} = json.apiError
          throw new Error(errorCode)
        })
      }
      return response.json()
    })
    .then(json => json)
    .catch(error => {
      throw new Error(`${error.message}`)
    })
}

export default client
