import React from 'react'
import {Dictionary} from '../types'

const nl2br = (text: string) =>
  text
    .split(/(?:\r\n|\r|\n)/)
    .reduce(
      (res: any, frag: any, i: any, arr: any) => [
        ...res,
        frag,
        ...(i < arr.length - 1 ? [React.createElement('br', {key: i})] : []),
      ],
      [],
    )

const dictionary: Dictionary = require('../languages/se.json')

function escapeRegExp(input: string = '') {
  return (input || '').replace(/([.*+?^${}()|[\]/\\])/g, '\\$1')
}

function reduceTemplate(
  this: Record<string, Dictionary>,
  template: string,
  key: string,
) {
  return template.replace(
    new RegExp('{\\s*' + escapeRegExp(key) + '\\s*}', 'g'),
    this.data[key],
  )
}

function translate(template: string, data?: Dictionary) {
  if (!data) {
    return template
  }
  return Object.keys(data).reduce(reduceTemplate.bind({data}), template)
}

const t = (key: string, data?: Record<string, any>, lineBreaks = true): any => {
  const translated = translate(dictionary[key] || key, data)
  return lineBreaks ? nl2br(translated) : translated.toString()
}

export {t, nl2br}
