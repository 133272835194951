/** @jsx jsx */
import {jsx} from '@emotion/core'
import {useLocation} from '@reach/router'
import {Fragment, FunctionComponent} from 'react'
import {useRecoilValue} from 'recoil'
import {currentPageState} from '../state'
import AlerisOtherInformations from './aleris-other-informations'
import OrthopedicOtherInformations from './orthopedic-other-informations'

const mapFeatures = (feature: string): FunctionComponent => {
  switch (feature) {
    case 'AlerisOtherInformations':
      return AlerisOtherInformations
    case 'OrthopedicOtherInformations':
      return OrthopedicOtherInformations
    default:
      return () => <Fragment />
  }
}

const Features = ({...props}) => {
  const location = useLocation()

  const {pathname} = location

  const currentPage = useRecoilValue(currentPageState(pathname))

  const {features = {}} = currentPage

  return (
    <div>
      {Object.keys(features).map((key: string) => {
        const Component = mapFeatures(key)
        return <Component key={key} {...props} />
      })}
    </div>
  )
}

export default Features
