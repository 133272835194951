/** @jsx jsx */
import {jsx} from '@emotion/core'

import Logo from '../../components/logo'
import Section from '../../components/section'
import {Form, Typography} from 'antd'
import useTranslate from '../../hooks/use-translate'
import {useRecoilValue} from 'recoil'
import {clientState} from 'src/state'

const ScreenCancelAleris = ({...props}) => {
  const client = useRecoilValue(clientState)

  const {t} = useTranslate({
    clientData: {
      ...client,
    },
  })

  return (
    <Form layout="vertical" autoComplete="off">
      <Section>
        <Logo />
      </Section>
      <Section>
        <Typography.Title>{t('SCREEN_CANCEL_TITLE')}</Typography.Title>
        <div className="paragraph">{t('SCREEN_CANCEL_MESSAGE')}</div>
        <div className="paragraph">
          <a href={`tel:${t('SCREEN_CANCEL_MESSAGE_PHONE')}`}>
            {t('SCREEN_CANCEL_MESSAGE_PHONE')}
          </a>
        </div>
      </Section>
    </Form>
  )
}

export default ScreenCancelAleris
