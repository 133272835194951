import {AppInstanceConfig} from '../types'
import {localStorageKey} from '../utils'
import client from './api-client'

function saveToken(token: string) {
  if (!token) {
    return
  }
  if (token) {
    window.localStorage.setItem(localStorageKey, token)
  }
  return token
}

function resetCurrentClient() {
  return Promise.resolve(window.localStorage.clear())
}

function getClientConfig(clientId = ''): Promise<AppInstanceConfig> {
  if (clientId === 'localhost') {
    //process.env.NODE_ENV === 'development' ||
    // return require(`./x-vision-ogonklinik.json`)
    throw new Error('No client found')
  }
  if (!clientId) {
    return {} as Promise<AppInstanceConfig>
  }
  // check if file exists
  if (clientId && require(`./${clientId}.json`)) {
    return require(`./${clientId}.json`)
  }

  return client('customer').then((response: any) => {
    if (!response.clientId) {
      return false
    }
    // Please leave this comment below for now
    // if (process.env.NODE_ENV === 'development') {
    //   return require('./aleris-eyes.json')
    // }

    return response
  })
}

function validateReferenceCode(referenceCode: string) {
  if (!referenceCode) {
    throw new Error('No referenceCode')
  }
  return client(`referenceCode?code=${referenceCode}`).then((response: any) => {
    if (response.referenceCode === referenceCode) {
      return true
    }
    return false
  })
}

function fetchOrganizations(referenceCode: string) {
  if (!referenceCode) {
    throw new Error('No referenceCode')
  }
  return client(`organizations?referenceCode=${referenceCode}`).then(
    (response: any) => response,
  )
}

function deleteUploadeFile(fileId: string) {
  if (!fileId) {
    throw new Error('No fileId')
  }
  return client(`patientFile/${fileId}`, {method: 'DELETE'}).catch(error => {
    throw new Error(error)
  })
}

function validatePromoCode(promoCode: string) {
  return client(`codes/?promoCode=${promoCode}`, {})
}

export function getReservationInfo(id: string) {
  return client(`calendarSlots/${id}`)
}

export function cancelBooking(id: string) {
  return client(`calendarSlots/${id}`, {
    method: 'DELETE',
  })
}

function getSlots(
  organizationId: string,
  start: string,
  end: string,
  referenceCode: string,
  promoCode = '',
) {
  return client(
    `calendarSlots?organizationId=${organizationId}&startTime=${start}&endTime=${end}${
      promoCode ? `&promoCode=${promoCode}` : ''
    }${referenceCode ? `&referenceCode=${referenceCode}` : ''}`,
  ).catch(error => {
    throw new Error(error)
  })
}

export {
  getSlots,
  validatePromoCode,
  getClientConfig,
  resetCurrentClient,
  saveToken,
  validateReferenceCode,
  fetchOrganizations,
  deleteUploadeFile,
}
