import moment from 'moment'
import {atom, DefaultValue, selector, selectorFamily} from 'recoil'
import {getClientConfig} from '../api/backend-client'
import {AppInstanceConfig, Page, Pages, TestType} from '../types'

export const clientState = selector({
  key: 'clientState',
  get: async ({get}) => {
    const url = window.location.hostname
    let clientId = url.split('.')[0]
    if (clientId === 'localhost') {
      clientId = 'x-vision-ogonklinik'
    }

    let client = await getClientConfig(clientId)
    // const clientId = client?.clientId

    if (!clientId) {
      throw new Error('No client found')
    }

    return client
  },
})

export const configState = atom<AppInstanceConfig>({
  key: 'configStateKey',
  default: {} as AppInstanceConfig,
})

type ScreenActionState = 'idle' | 'saving'

export const screenActionState = atom<ScreenActionState>({
  key: 'screenActionState',
  default: 'idle',
})

export const screenErrorState = atom<null | string>({
  key: 'screenErrorState',
  default: null,
})

export const currentPageState = selectorFamily({
  key: 'currentPageState',
  get:
    (path: string) =>
    ({get}) => {
      if (!path) {
        throw new Error('No path found')
      }
      const client = get(clientState)
      return client.pages[path]
    },
})

export const activeRoutesSelector = selector<Pages>({
  key: 'activeRoutesSelectorKey',
  get: ({get}) => {
    const config = get(configState)
    const state: any = get(appState)

    if (config.pages) {
      return Object.keys(config.pages).reduce((accumulator: any, route) => {
        const row = config.pages[route]
        if (row?.showWhen?.every) {
          if (Object.keys(row?.showWhen?.every)) {
            if (
              Object.keys(row?.showWhen?.every).every(key => {
                return state[key] === row?.showWhen?.every[key]
              })
            ) {
              accumulator[route] = row
            }
          }
        } else if (row?.showWhen?.some) {
          if (Object.keys(row?.showWhen?.every)) {
            if (
              Object.keys(row?.showWhen?.every).some(key => {
                return state[key] === row?.showWhen?.every[key]
              })
            ) {
              accumulator[route] = row
            }
          }
        } else {
          accumulator[route] = row
        }

        return accumulator
      }, {})
    }
    return {}
  },
})

const findNearestPath = (
  routes: Pages,
  activeRoutes: Pages,
  currentRoute: Page,
): string => {
  const nextPath = currentRoute?.nextRoute

  if (nextPath) {
    if (activeRoutes[nextPath]) {
      return nextPath
    } else if (routes?.[nextPath]?.nextRoute) {
      return findNearestPath(routes, activeRoutes, routes[nextPath])
    }
  }

  return nextPath ?? '/'
}

export const nextRouteSelector = selectorFamily<string, string>({
  key: 'nextRouteSelectorKey',
  get:
    currentRoutePath =>
    ({get}) => {
      const config = get(configState)
      const activeRoutes = get(activeRoutesSelector)

      const currentRoute = config?.pages?.[currentRoutePath]

      return findNearestPath(config.pages, activeRoutes, currentRoute)
    },
})

type ReferralType = 'form' | 'upload'

type PhoneTypeEnum = 'MOBILE' | 'FIXED_LINE'

export interface AppState {
  referenceCode: string
  organizationId: string | null
  organizationName: string | null
  cityId: string | null
  skipBooking: boolean
  slotsAvailable: boolean
  youngPerson: boolean
  referralType: ReferralType
  promoCode: string
  startTime: string
  withoutAppointment: boolean
  phoneNumber: string
  mobileNumber: string
  personalNumber: string
  firstName: string
  lastName: string
  files: string[]
  agree: boolean
  otherInformationValue: any
  otherInformation: any
  countryCode: string
  phoneType: PhoneTypeEnum
  testType: TestType
  defaultCountryCode: string
  recaptchaToken: string | null
  refractionDate: moment.Moment
}

export const appState = atom<AppState>({
  key: 'appStateKey',
  default: {
    slotsAvailable: false,
    skipBooking: false,
    youngPerson: false,
  } as AppState,
  effects_UNSTABLE: [
    ({onSet, setSelf, trigger}) => {
      const localStorageKey = 'kindBookingAppState'

      if (trigger === 'get') {
        const storedData = localStorage.getItem(localStorageKey)
        if (storedData !== null) {
          setSelf(JSON.parse(storedData))
        }
      }

      onSet(newState => {
        if (newState instanceof DefaultValue) {
          localStorage.removeItem(localStorageKey)
        } else {
          localStorage.setItem(localStorageKey, JSON.stringify(newState))
        }
      })
    },
  ],
})
