/** @jsx jsx */
import {jsx} from '@emotion/core'

import useScreen from '../../hooks/use-screen'
import Navigation from '../../components/navigation'
import Form from 'antd/lib/form/Form'

import renderer from '../../renderer'

const ScreenDynamic = ({...props}) => {
  const {currentPage, form, storedValue, handleNext} = useScreen()

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        width: '100%',
        height: '100vh',
      }}
    >
      <Form
        autoComplete="off"
        form={form}
        layout="vertical"
        initialValues={storedValue}
        onFinish={() => handleNext(form)}
      >
        {renderer(currentPage.layout) || (
          <div
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100vh',
            }}
          >
            404
          </div>
        )}
        <Navigation form={form} />
      </Form>
    </div>
  )
}

export default ScreenDynamic
