/** @jsx jsx */
import {jsx} from '@emotion/core'
import * as style from './logo.style'
import {useRecoilValue} from 'recoil'
import {clientState} from '../../state'

const Logo = () => {
  const client = useRecoilValue(clientState)

  return (
    <a href="/">
      <img
        alt="Booking by KIND"
        width={150}
        src={client.logo}
        css={style.logo}
      />
    </a>
  )
}

export default Logo
