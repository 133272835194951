import {css} from '@emotion/core'

export const page = css`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  max-width: 740px;

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
`
export const pageMobile = css`
  padding-left: 15px;
  padding-right: 15px;
`
