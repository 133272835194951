export const localStorageKey = '__kind_booking_client__'

export const renderErrorMessage = (
  errorCode: string,
  t: (key: string) => {},
) => {
  const mapErrors: any = {
    APP_ERROR_1000: t('APP_ERROR_1000'),
    APP_ERROR_NIN_ALREADY_BOOKED: t('APP_ERROR_NIN_ALREADY_BOOKED'),
    APP_ERROR_PHONE_ALREADY_BOOKED: t('APP_ERROR_PHONE_ALREADY_BOOKED'),
    APP_ERROR_ALREADY_BOOKED: t('APP_ERROR_ALREADY_BOOKED'),
    APP_ERROR_BOOKED_RESULTS_NOT_SENT: t('APP_ERROR_BOOKED_RESULTS_NOT_SENT'),
    APP_ERROR_TIMESLOT_NOT_AVAILABLE: t('APP_ERROR_TIMESLOT_NOT_AVAILABLE'),
    APP_ERROR_NO_START_TIME_SELECTED: t('APP_ERROR_NO_START_TIME_SELECTED'),
    APP_ERROR_GENERAL: t('APP_ERROR_GENERAL'),
  }

  return (
    mapErrors[`APP_ERROR_${errorCode}`] ||
    `${mapErrors[`APP_ERROR_GENERAL`]} (${errorCode})`
  )
}

export const truncateFileName = (text: string = '', length: number = 20) => {
  var ext = text.substring(text.lastIndexOf('.') + 1, text.length).toLowerCase()
  var filename = text.replace('.' + ext, '')
  if (filename.length <= length) {
    return text
  }
  filename = filename.substr(0, length) + (text.length > length ? '[...]' : '')
  return filename + '.' + ext
}

export const isString = <T>(value: T) => typeof value === 'string'

export const upper = (value = '') => value.toUpperCase()

export const minLength = (value = '', min = 1) => {
  if (!isString(value)) {
    return false
  }

  // Validate length argument
  if (!Number.isFinite(min)) {
    throw new TypeError('Length must be a finite number')
  }
  if (min < 1) {
    throw new Error('Length must be a positive number greater than 0')
  }

  return value.length >= min
}

const pattern = /(^|[ -])([^ -])([^ -]+)/g

export const replacer = (
  _m: string,
  separator: string,
  first: string,
  rest: string,
) => `${separator}${upper(first)}${rest}`

export const capitalize = (value = '') =>
  minLength(value) && value.replace(pattern, replacer)

export default capitalize
