/** @jsx jsx */
import {jsx} from '@emotion/core'

import Logo from '../../components/logo'
import Section from '../../components/section'
import {Typography} from 'antd'
import useScreen from '../../hooks/use-screen'
import Navigation from '../../components/navigation'
import HeadImage from '../../components/head-image'
import Form from 'antd/lib/form/Form'
import DefaultButton from '../../components/default-button'
import {t} from '../../utils/translate'

const ScreenThankYou = () => {
  const {currentPage} = useScreen()

  const goHome = () => {
    window.localStorage.removeItem('kindBookingAppState')
    window.location.href = '/'
  }

  return (
    <Form>
      <Section>
        <Logo />
      </Section>
      {Boolean(currentPage?.headImage) && (
        <HeadImage src={currentPage?.headImage} />
      )}
      <Section style={{marginRight: 'auto', marginLeft: 'auto'}}>
        <Typography.Title>{t('SCREEN_THANK_YOU_TITLE')}</Typography.Title>
        <p>{t('SCREEN_THANK_YOU_LINE1')}</p>
        <p>{t('SCREEN_THANK_YOU_LINE2')}</p>
        <div className="align-center mt-30">
          <DefaultButton size="large" onClick={goHome}>
            {t('GENERIC_THANKYOU_BUTTON')}
          </DefaultButton>
        </div>
      </Section>
      <Navigation />
    </Form>
  )
}

export default ScreenThankYou
