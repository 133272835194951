/** @jsx jsx */
import {jsx} from '@emotion/core'
import {Form, FormInstance, Radio, Space} from 'antd'
import {Fragment, ReactNode} from 'react'
import useTranslate from 'src/hooks/use-translate'
import useScreen from '../../hooks/use-screen'
import {useRecoilValue} from 'recoil'
import {clientState} from 'src/state'

interface OrthopedicOtherInformationsProps {
  form?: FormInstance
  children?: ReactNode
}

const OrthopedicOtherInformations = ({
  form,
}: OrthopedicOtherInformationsProps) => {
  const {updateState, storedValue} = useScreen()

  const client = useRecoilValue(clientState)

  const {t} = useTranslate({
    clientData: {
      ...client,
    },
  })

  return (
    <Fragment>
      <Form.Item
        label={<span className="labelRequired">Övrig information</span>}
        className="formItemCombined"
      >
        <Space direction="horizontal">
          <Radio
            value={'no'}
            checked={storedValue.otherInformation === 'no'}
            onClick={() => {
              updateState(currentState => ({
                ...currentState,
                otherInformation: 'no',
                otherInformationValue: [],
              }))
              form?.setFieldsValue({otherInformationValue: []})
            }}
          >
            {t('OTHER_INFO_NO')}
          </Radio>
          <Radio
            value={'yes'}
            checked={storedValue.otherInformation === 'yes'}
            onClick={() => {
              updateState(currentState => ({
                ...currentState,
                otherInformation: 'yes',
                otherInformationValue: [t('OTHER_INFO_YES')],
              }))
            }}
          >
            {t('OTHER_INFO_YES')}
          </Radio>
        </Space>
      </Form.Item>
      {Boolean(storedValue?.otherInformationValue?.length) && (
        <Form.Item>
          <div className="highlightedBox">
            {storedValue?.otherInformationValue?.includes(
              t('OTHER_INFO_YES'),
            ) && <div>{t('SCREEN_OTHER_LINE_3')}</div>}
          </div>
        </Form.Item>
      )}
    </Fragment>
  )
}

export default OrthopedicOtherInformations
