import React, {useState} from 'react'
import {GDPR_COOKIE_NAME} from '../../config'
import {t} from '../../utils/translate'
import DefaultButton from '../default-button'

const GDPRConsent = () => {
  const [consent, setConsent] = useState(
    document.cookie
      .split('; ')
      .find(row => row.startsWith(GDPR_COOKIE_NAME))
      ?.split('=')[1] || false,
  )

  const onClick = () => {
    const now = new Date()
    now.setMonth(now.getMonth() + 12)
    document.cookie = `${GDPR_COOKIE_NAME}=1;`
    document.cookie = `expires=${now.toUTCString()};`
    setConsent(true)
  }

  if (!consent) {
    return (
      <div className="aleris-gdpr-bar">
        <div className="text">
          {t('GDPR_CONSENT_TEXT')}{' '}
          <a
            href={t('GDPR_CONSENT_URL')}
            rel="noopener noreferrer"
            target={'_blank'}
          >
            Läs mer
          </a>
        </div>

        <DefaultButton className="gdpr-button" onClick={onClick}>
          {t('GDPR_CONSENT_BUTTON_NAME')}
        </DefaultButton>
      </div>
    )
  } else {
    return null
  }
}

export default GDPRConsent
