/** @jsx jsx */
import {jsx} from '@emotion/core'

import {Fragment} from 'react'
import Logo from '../../components/logo'
import Section from '../../components/section'
import {Typography} from 'antd'
import useScreen from '../../hooks/use-screen'
import Navigation from '../../components/navigation'
import DefaultButton from '../../components/default-button'
import {navigate} from '@reach/router'
import HeadImage from '../../components/head-image'

const ScreenCancelConfirmation = () => {
  const {currentPage} = useScreen()

  const onClick = () => navigate('/')

  return (
    <Fragment>
      <Section>
        <Logo />
      </Section>
      {Boolean(currentPage?.headImage) && (
        <HeadImage src={currentPage?.headImage} />
      )}
      <Section>
        <div>
          <Typography.Title>Din tid är avbokad</Typography.Title>
        </div>
        <div>
          Du har avbokat din tid för antikroppstest för covid-19. För att boka
          en ny tid klicka på knappen nedan.
        </div>
      </Section>
      <Section>
        <DefaultButton size="large" onClick={onClick}>
          Boka ny tid
        </DefaultButton>
      </Section>
      <Navigation />
    </Fragment>
  )
}

export default ScreenCancelConfirmation
