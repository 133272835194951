import {css} from '@emotion/core'
import * as theme from './theme'

const getColor = (color: string, theme: any, customTheme: any) => {
  if (customTheme[color]) {
    return customTheme[color]
  }

  return theme[color]
}

export const GlobalStyle = (customTheme = {} as any) => css`
  @import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,700;0,900;1,400&display=swap');

  /* define variable */
  :root {
    --primary-color: ${getColor('PrimaryColor', theme, customTheme)};
    --primary-border-color: ${getColor(
      'PrimaryBorderColor',
      theme,
      customTheme,
    )};
    --default-color: ${getColor('DefaultColor', theme, customTheme)};
    --default-border-color: ${getColor(
      'DefaultBorderColor',
      theme,
      customTheme,
    )};
    --border-line-color: ${getColor('BorderLineColor', theme, customTheme)};
    --classic-component-background: ${getColor(
      'ClassicComponentBackground',
      theme,
      customTheme,
    )};
    --primary-highlight-color: ${getColor(
      'PrimaryHighlightColor',
      theme,
      customTheme,
    )};
    --secondary-color: ${getColor('SecondaryColor', theme, customTheme)};
    --navigation-color: ${getColor('NavigationColor', theme, customTheme)};
    --link-color: ${getColor('LinkColor', theme, customTheme)};
    --highlighted-color: ${getColor('HighlightedColor', theme, customTheme)};
    --text-color: ${getColor('TextColor', theme, customTheme)};
    --table-column-frozen: ${getColor('TableColumnFrozen', theme, customTheme)};
    --background-color: ${getColor('BackgroundColor', theme, customTheme)};
    --white-color: ${getColor('WhiteColor', theme, customTheme)};
    --red-color: ${getColor('RedColor', theme, customTheme)};
    --green-color: ${getColor('GreenColor', theme, customTheme)};
    --transparent: ${getColor('Transparent', theme, customTheme)};
    --gray-color: ${getColor('GrayColor', theme, customTheme)};
    --black-color: ${getColor('BlackColor', theme, customTheme)};
    --light-black-color: ${getColor('LightBlackColor', theme, customTheme)};
    --orange-color: ${getColor('OrangeColor', theme, customTheme)};
    --yellow-color: ${getColor('YellowColor', theme, customTheme)};
    --lighter-yellow-color: ${getColor(
      'LighterYellowColor',
      theme,
      customTheme,
    )};
    --light-yellow-color: ${getColor('LightYellowColor', theme, customTheme)};
    --lighter-gray-color: ${getColor('LighterGrayColor', theme, customTheme)};
    --light-gray-color: ${getColor('LightGrayColor', theme, customTheme)};
    --lime-color: ${getColor('LimeColor', theme, customTheme)};
    --blue-color: ${getColor('BlueColor', theme, customTheme)};
    --lighter-blue-color: ${getColor('LighterBlueColor', theme, customTheme)};
    --brand-color: ${getColor('BrandColor', theme, customTheme)};
    --border-radius-base: ${getColor('BorderRadiusBase', theme, customTheme)};
    --border-width-base: ${getColor('BorderWidthBase', theme, customTheme)};
    --placeholder-icon-color: ${getColor(
      'PlaceholderIconColor',
      theme,
      customTheme,
    )};
    --button-on-dark-color: ${getColor(
      'ButtonOnDarkColor',
      theme,
      customTheme,
    )};
  }

  html,
  body {
    height: 100%;
    background-color: ${customTheme.WhiteColor || theme.WhiteColor};
    font-family: Rubik, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    color: ${theme.TextColor};
  }

  #root {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: column;
  }

  .ant-form {
    padding-bottom: 100px !important;
  }

  .ant-form-vertical {
    width: 100%;

    > div.section {
      margin-left: auto;
      margin-right: auto;
    }

    .ant-input {
      padding: 10px 15px;
    }

    .ant-select-lg .ant-select-selector {
      height: 50px !important;
      padding: 5px 15px !important;
    }
  }

  .helllo {
    color: red;
  }

  .ant-form-item-label {
    font-family: Rubik;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    color: #084f58 !important;

    label {
      color: #084f58 !important;
    }
  }

  .ant-form-item-explain {
    margin-top: 5px !important;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    color: #a3b9bd;
  }

  .ant-btn[disabled] {
    border-color: #00c6b2 !important;
    background-color: #00c6b2;
    opacity: 0.5;
  }

  .formItemCombined {
    margin-bottom: 5px !important;

    .ant-form-item-label {
      padding-bottom: 0 !important;
    }
  }

  .ant-form-item:last-of-type {
    margin-bottom: 0;
  }

  .radioOption {
    margin: 5px 0;
  }

  .highlightedBox {
    background-color: ${theme.HighlightedColor};
    padding: 15px;
    border-radius: ${theme.BorderRadiusBase};
    font-size: 14px;

    .ant-form-item {
      margin: 0;
    }

    > div {
      margin-bottom: 10px;
    }

    > div:last-of-type {
      margin-bottom: 0;
    }
  }

  .highlightedBoxNormal {
    font-size: inherit;
  }

  .summaryBox {
    background-color: #ebf0f1;
    padding: 15px;
    border-radius: ${theme.BorderRadiusBase};

    .summaryBoxAction {
      cursor: pointer;
      font-size: 16px;
      float: right;
    }

    .summaryField {
      margin-bottom: 15px;

      &:last-of-type {
        margin-bottom: 0;
      }

      .summaryLabel {
        font-weight: 500;
      }
    }
  }

  h1.ant-typography,
  h2.ant-typography {
    color: ${theme.TextColor}!important;
    font-weight: 500 !important;
    border-bottom: 1px solid ${theme.LightGrayColor};
    padding-bottom: 5px;

    > div {
      padding: 6px 0;
      font-weight: normal;
    }
  }

  .errorMessage {
    color: ${theme.RedColor};
  }

  .aleris-gdpr-bar {
    position: fixed;
    z-index: 999;
    left: 0;
    width: 100%;
    background-color: #ebf0f1;
    padding: 24px;

    .text {
      display: inline-block;
      padding-right: 24px;
      display: block;
      width: 100%;
      padding-right: 0px;
      padding-bottom: 24px;
      text-align: center;
    }

    .gdpr-button {
      width: 249px;
      height: 50px;
      background-color: #00c6b2;
      border: none;
      color: #fff;
      font-weight: 500;
      vertical-align: bottom;
    }
  }

  .horizontalPaddingLeft {
    padding-left: 15px;
  }

  .horizontalPaddingTop {
    padding-top: 15px;
  }

  .flagHolder {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
      margin: 0 0 0 5px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .hidden {
    display: none;
  }

  .uiTable {
    border: 1px solid #a3b9bd;
    border-radius: ${theme.BorderRadiusBase};
    margin-bottom: 24px;

    .ant-form-item-explain {
      display: none !important;
    }

    .cell {
      margin: 0;
      background: ${theme.WhiteColor};

      .ant-input {
        font-size: 14px;
        padding-left: 0;
        padding-right: 0;
      }
    }

    .ant-input-lg {
      border-radius: 0 !important;
    }

    .readOnlyValue {
      text-align: center;
    }

    .ant-row {
      width: 100%;
      display: flex;
      flex: 1;
      flex-wrap: nowrap;
      flex-grow: 1;
    }

    .frozen {
      background-color: ${theme.TableColumnFrozen};
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 14px;

      &:last-of-type {
        border-top: 0;
        border-right: 0;
      }

      &:first-of-type {
        border-right: 0;
        border-left: 0;
      }
    }

    .ant-col {
      border-left: 1px solid ${theme.LightGrayColor};
      border-bottom: 1px solid ${theme.LightGrayColor};
      display: flex;
      flex: 1;
      align-items: grow;
      justify-content: center;

      &:last-of-type {
        border-top: 0;
        border-right: 0;
      }

      &:first-of-type {
        border-right: 0;
      }

      .ant-input {
        text-align: center;
      }
    }
  }

  .messageError {
    border: 1px solid ${theme.RedColor};
    border-radius: ${theme.BorderRadiusBase};
    padding: 15px;
    color: ${theme.RedColor};
    font-size: 14px;
  }

  .paragraph {
    margin-bottom: 15px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .clickable {
    cursor: pointer;
  }

  .appointment-picker {
    margin: 25px auto;
    box-sizing: border-box;
    &.loading {
      padding-top: 284px;
      text-align: center;
    }

    .no-slots {
      text-align: center;
      margin-top: 120px;
    }

    .week-picker {
      margin-bottom: 25px;
      text-align: center;
      .selector {
        @include ie11_only() {
          padding-bottom: 11px;
        }
        border-radius: 0;
        border-color: #c2d4d6;
        border-left-width: 0;
        border-right-width: 0;
        color: #084f58;
        height: 35px;
        width: 87px;
        input {
          text-align: center;
          font-weight: 500;
          font-size: 14px;
        }
      }
      button {
        color: #084f58;
        border-color: #c2d4d6;
        height: 35px;
        width: 35px;
        vertical-align: bottom;
        span.anticon {
          vertical-align: 1px;
        }
        &:first-of-type {
          border-radius: 4px 0 0 4px;
        }
        &:last-of-type {
          border-radius: 0 4px 4px 0;
        }
      }
      .button-icon {
        font-size: 15px;
      }
    }

    .days-picker {
      @include respond-to('small') {
        max-width: calc(100vw - 20px);
        width: calc(100vw - 20px);
      }
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      .scroll-container {
        width: 568px;
        display: block;
        margin: 0 auto;
      }
      .day {
        display: inline-block;
        width: 108px;
        height: 342px;
        border-radius: 6px;
        border: solid 1px #c2d4d6;
        background-color: rgba(255, 255, 255, 0);
        margin-left: 7px;
        overflow: hidden;
        &:first-of-type {
          margin-left: 0px;
        }
        .calendar-day {
          > div {
            text-align: center;
            color: #084f58;
            text-transform: lowercase;
          }
          .day-name {
            font-size: 16px;
            font-weight: 500;
          }
          .day-string {
            font-size: 36px;
            font-weight: bold;
            line-height: 36px;
          }
          .month-name {
            font-size: 16px;
            font-weight: 500;
          }
          padding: 12px 0;
          border-bottom: 1px solid #c2d4d6;
        }
        .hours {
          height: 236px;
          overflow-x: auto;
          -webkit-overflow-scrolling: touch;
          scroll-behavior: smooth;
          .hour {
            font-size: 12px;
            text-align: center;
            line-height: 34px;
            border-bottom: 1px solid #c2d4d6;
            background-color: #fff;
            color: #c2d4d6;
            &.available {
              cursor: pointer;
              background-color: #e8f9f4;
              color: #084f58;
              pointer-events: all;
            }
            &.active {
              cursor: pointer;
              background-color: ${theme.PrimaryColor};
              color: #fff;
              pointer-events: all;
            }
          }
        }
      }
      .mobile-view {
        .titles {
          border-radius: 6px 6px 0 0;
          border: 1px solid #c2d4d6;
          height: 106px;
          .day {
            height: 106px;
            border-radius: 6px 6px 0 0;
          }
        }
        .slots {
          height: 236px;
          overflow-x: auto;
          -webkit-overflow-scrolling: touch;
          scroll-behavior: smooth;
          border-radius: 0 0 6px 6px;
          border: 1px solid #c2d4d6;
          border-top: 0px solid #fff;
          margin-top: 0px;
          .day {
            height: auto;
            overflow: visible;
            -webkit-overflow-scrolling: touch;
            border-radius: 0 0 6px 6px;
            border-top: 0px solid #fff;
            border-bottom: 0px solid #fff;
            .hour {
              &:last-of-type {
                border-bottom: 0px solid #fff;
              }
            }
            .hours {
              height: auto;
            }
          }
        }
        .titles,
        .slots {
          .day {
            margin-left: 0px;
            border: 0px solid #fff;
            border-radius: 0;
            border-right: 1px solid #c2d4d6;
            width: 113px;
            &:last-of-type {
              border-right: 0px solid #c2d4d6;
            }
            .calendar-day {
              border-bottom: 0px solid #fff;
              padding: 10px 0;
            }
          }
        }
      }
    }
  }

  .hidden {
    display: none;
  }

  .ant-radio-wrapper {
    display: flex;
    align-items: baseline;
  }

  .ant-radio-group {
    .ant-radio-wrapper {
      display: flex;
      align-items: center;
    }
  }

  span.ant-radio + * {
    font-size: 1rem !important;
  }

  .FileAttached {
    background-color: ${theme.WhiteColor};
    border: 1px solid ${theme.BorderLineColor};
    padding: 8px 12px;
    border-radius: 6px;
    color: #084f58;
    cursor: pointer;
    margin-bottom: 5px;
  }

  .FileAttached:last-of-type {
    margin-bottom: 0;
  }

  .kindMessage2 {
    position: absolute;
    display: flex;
    justify-content: center;
    z-index: 10;
    bottom: 80px;
    width: 100%;
    max-width: 100%;

    > div {
      background-color: red;
      color: white;
    }
  }

  .labelRequired::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 16px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }

  .align-center {
    text-align: center;
  }

  .mt-30 {
    margin-top: 30px;
  }

  .mt-15 {
    margin-top: 15px;
  }

  .my-15 {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .my-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .ant-form-item-explain div {
    color: var(--primary-color);
  }
`
