/** @jsx jsx */
import {jsx} from '@emotion/core'
import {Checkbox, Col, Form, FormInstance, Radio, Row} from 'antd'
import {Fragment, ReactNode, useState} from 'react'
import useScreen from '../../hooks/use-screen'
import useTranslate from '../../hooks/use-translate'
import {useRecoilValue} from 'recoil'
import {clientState} from 'src/state'

interface AlerisOtherInformations {
  form?: FormInstance
  children?: ReactNode
}

const AlerisOtherInformations = ({form}: AlerisOtherInformations) => {
  const {updateState, storedValue} = useScreen()

  const client = useRecoilValue(clientState)

  const {t} = useTranslate({
    clientData: {
      ...client,
    },
  })

  const [isRequired, setIsRequired] = useState(
    storedValue.otherInformation === 'no' ? false : true,
  )

  return (
    <Fragment>
      <Form.Item
        label={<span className="labelRequired">Övrig information</span>}
        className="formItemCombined"
      >
        <Radio
          value={'no'}
          checked={storedValue.otherInformation === 'no'}
          onClick={() => {
            updateState(currentState => ({
              ...currentState,
              otherInformation: 'no',
              otherInformationValue: [],
            }))
            form?.setFieldsValue({otherInformationValue: []})
            setIsRequired(false)
          }}
        >
          Nej, inget att tillägga
        </Radio>
        <Radio
          value={'yes'}
          checked={storedValue.otherInformation === 'yes'}
          onClick={() => {
            updateState(currentState => ({
              ...currentState,
              otherInformation: 'yes',
            }))
            setIsRequired(true)
          }}
        >
          Ja, välj vad eller vilka nedan
        </Radio>
      </Form.Item>
      <Form.Item
        name="otherInformationValue"
        rules={[{required: isRequired, message: t('GENERIC_INFO_REQUIRED')}]}
      >
        <Checkbox.Group
          disabled={storedValue.otherInformation === 'no'}
          style={{width: '100%'}}
          value={storedValue.otherInformationValue}
          onChange={(checkedValue: any) =>
            updateState(currentState => ({
              ...currentState,
              otherInformationValue: checkedValue,
            }))
          }
        >
          <Row>
            <Col offset={2} span={11}>
              <Checkbox value="Tolkbehov">Tolkbehov</Checkbox>
            </Col>
            <Col span={11}>
              <Checkbox value="Blodsmitta">Blodsmittan</Checkbox>
            </Col>
            <Col offset={2} span={11}>
              <Checkbox value="MRSA">MRSA</Checkbox>
            </Col>
            <Col span={11}>
              <Checkbox value="Rullstol / Permobil">
                Rullstol / Permobil
              </Checkbox>
            </Col>
          </Row>
        </Checkbox.Group>
      </Form.Item>
      {Boolean(storedValue?.otherInformationValue?.length) && (
        <Form.Item>
          <div className="highlightedBox">
            {storedValue?.otherInformationValue?.includes('Tolkbehov') && (
              <div>{t('SCREEN_OTHER_LINE_1')}</div>
            )}
            {storedValue?.otherInformationValue?.includes('Blodsmitta') && (
              <div>{t('SCREEN_OTHER_LINE_4')}</div>
            )}
            {storedValue?.otherInformationValue?.includes('MRSA') && (
              <div>{t('SCREEN_OTHER_LINE_2')}</div>
            )}
            {storedValue?.otherInformationValue?.includes(
              'Rullstol / Permobil',
            ) && (
              <div>
                Informera kunden om att hissarna i huset är små och man kommer
                tyvärr inte upp med större rullstol, ring oss vid frågor 000 00
                00 00. Behöver patienten rullas in till OP måste vi boka besöket
                sist på dagen pga städrutiner efter en rullstol varit i salen.
              </div>
            )}
          </div>
        </Form.Item>
      )}
    </Fragment>
  )
}

export default AlerisOtherInformations
