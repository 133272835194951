import {css} from '@emotion/core'
import theme from '../../theme'

export const recaptchaMessage = css`
  font-size: 14px;
  color: ${theme.GrayColor};
  max-width: 570px;
  margin-left: auto;
  margin-right: auto;

  a {
    color: ${theme.TextColor};
  }
`

export const navigation = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px 0;
  background: ${theme.NavigationColor};
  color: ${theme.WhiteColor};

  > .ant-col {
    padding: 0 15px;
  }
`

export const navigationLeft = css`
  background: pink;
`

export const navigationRight = css`
  background: lightblue;
`

export const fullWidth = css`
  width: 100% !important;
`

export const activeItem = css`
  font-weight: 600 !important;
  color: ${theme.WhiteColor};
  opacity: 1 !important;
`

export const breadcrumbs = css`
  display: flex;

  > div {
    padding-right: 10px;
    color: ${theme.WhiteColor};
    opacity: 0.6;
    font-weight: 500;
  }

  > div::after {
    padding-left: 10px;
    content: '>';
  }

  > div:last-of-type {
    padding-right: 0;

    &::after {
      content: '';
    }
  }
`
