/** @jsx jsx */
import {jsx} from '@emotion/core'
import {createElement} from 'react'

import Logo from '../components/logo'
import Section from '../components/section'
import {Typography} from 'antd'
import HeadImage from '../components/head-image'
import DefaultButton from '../components/default-button'
import {t} from '../utils/translate'
import shortid from 'shortid'
import InputReferenceCode from '../components/input-reference-code'

declare global {
  interface Window {
    kindClientConfig: any
  }
}

const KeysToComponentMap: any = {
  Section: Section,
  Logo: Logo,
  HeadImage: HeadImage,
  Title: Typography.Title,
  Paragraph: Typography.Paragraph,
  DefaultButton: DefaultButton,
  InputReferenceCode: InputReferenceCode,

  div: ({children, className}: any) => (
    <div className={className}>{children}</div>
  ),

  Translate: ({children, clientName}: any) => {
    if (typeof children === 'string') {
      return t(children, {
        ...window.kindClientConfig,
      })
    }
    return children
  },
}

const generateKey = (name: string) => `${name}_${shortid.generate()}`

function renderer(config: any) {
  if (typeof KeysToComponentMap[config?.component] !== 'undefined') {
    return createElement(
      KeysToComponentMap[config.component],
      {
        ...config,
        key: generateKey(config.component),
      },
      config.children &&
        (typeof config.children === 'string'
          ? config.children
          : config.children.map((c: any) => renderer(c))),
    )
  }
}

export default renderer
