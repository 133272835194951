/** @jsx jsx */
import {jsx} from '@emotion/core'

import Logo from '../../components/logo'
import Section from '../../components/section'
import {Dropdown, Form, Input, Menu, Radio, Space, Typography} from 'antd'

import Features from '../../features'
import useScreen from '../../hooks/use-screen'
import Navigation from '../../components/navigation'

import Personnummer from 'personnummer'

import {DownOutlined} from '@ant-design/icons'
import useTranslate from '../../hooks/use-translate'

import parsePhoneNumber from 'libphonenumber-js/max'

const ScreenCustomerDetails = () => {
  const screen = useScreen()

  const {form, storedValue, isSaving, client, updateState, handleNext} = screen

  const {t} = useTranslate({
    clientData: {
      ...client,
    },
  })

  const {allowedAge, countries} = client

  const selectedCountry = countries.find(
    country =>
      country.phoneCode ===
      (storedValue.countryCode ||
        storedValue.defaultCountryCode ||
        countries[0].phoneCode),
  )

  const menuCountries = () => (
    <Menu>
      {countries.map(country => (
        <Menu.Item
          key={country.code}
          onClick={() => {
            updateState(currentState => ({
              ...currentState,
              countryCode: country.phoneCode,
            }))
            form.setFieldsValue({countryCode: country.phoneCode})
            form.validateFields(['phoneNumber'])
          }}
        >
          <div className="flagHolder">
            <img src={`/flags/${country.icon}`} alt="Sweden" />
            <div>{country.phoneCode}</div>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <Form
      name="details"
      form={form}
      layout="vertical"
      initialValues={storedValue}
      autoComplete="off"
      validateTrigger="onSubmit"
      onFinish={() => handleNext(form)}
    >
      <Section>
        <Logo />
      </Section>

      <Section>
        <Form.Item
          className="formItem"
          label={t('SCREEN_DETAILS_REF_CODE_LABEL_VALID')}
          help={
            <div style={{color: '#2fad3b'}}>
              {t('SCREEN_DETAILS_REF_CODE_CONFIRMED')}
            </div>
          }
        >
          <Input
            size="large"
            disabled={true}
            value={storedValue.referenceCode}
          />
        </Form.Item>
      </Section>
      <Section>
        <Typography.Title>{t('SCREEN_DETAILS_TITLE')}</Typography.Title>

        <Form.Item
          name="firstName"
          className="formItem"
          label={t('SCREEN_DETAILS_FIRST_NAME_LABEL')}
          rules={[
            {required: true, message: t('SCREEN_DETAILS_FIRST_NAME_ERROR')},
          ]}
        >
          <Input size="large" autoFocus disabled={isSaving} />
        </Form.Item>
        <Form.Item
          name="lastName"
          className="formItem"
          label={t('SCREEN_DETAILS_LAST_NAME_LABEL')}
          rules={[{required: true, message: 'Ange ditt efternamn'}]}
        >
          <Input size="large" disabled={isSaving} />
        </Form.Item>
        <Form.Item
          name="personalNumber"
          className="formItem"
          label={t('SCREEN_DETAILS_NIN_LABEL')}
          rules={[
            {required: true, message: ' '},
            () => ({
              validator(_, value) {
                if (value && Personnummer.valid(value)) {
                  const age = Personnummer.parse(value).getAge()

                  if (age < allowedAge) {
                    return Promise.reject(
                      t('SCREEN_DETAILS_NIN_ERROR_TOO_YOUNG'),
                    )
                  }

                  return Promise.resolve()
                }
                return Promise.reject(t('SCREEN_DETAILS_NIN_ERROR_INVALID'))
              },
            }),
          ]}
        >
          <Input size="large" disabled={isSaving} />
        </Form.Item>
        <Form.Item
          label={
            <span className="labelRequired">
              {t('SCREEN_DETAILS_PHONE_LABEL')}
            </span>
          }
          className="formItemCombined"
        >
          <Space direction="horizontal">
            <Radio
              value={'MOBILE'}
              checked={storedValue.phoneType === 'MOBILE'}
              onClick={() => {
                updateState(currentState => ({
                  ...currentState,
                  phoneType: 'MOBILE',
                  bookingAvailable: 'yes',
                  phoneNumber: '',
                  mobileNumber: '',
                }))
                form.setFieldsValue({phoneNumber: ''})
                form.validateFields(['phoneNumber'])
              }}
            >
              {t('SCREEN_DETAILS_PHONE_MOBILE')}
            </Radio>
            <Radio
              value={'FIXED_LINE'}
              checked={storedValue.phoneType === 'FIXED_LINE'}
              onClick={() => {
                updateState(currentState => ({
                  ...currentState,
                  phoneType: 'FIXED_LINE',
                  bookingAvailable: 'no',
                  startTime: '',
                  phoneNumber: '',
                  mobileNumber: '',
                }))
                form.setFieldsValue({phoneNumber: ''})
                form.validateFields(['phoneNumber'])
              }}
            >
              {t('SCREEN_DETAILS_PHONE_LANDLINE')}
            </Radio>
          </Space>
        </Form.Item>
        <Form.Item
          shouldUpdate={(prevValues: any, nextValues: any) =>
            prevValues.countryCode !== nextValues.countryCode
          }
          validateTrigger="onChange"
          name="phoneNumber"
          className="formItem"
          rules={[
            {
              required: true,
              message: ' ',
              pattern: new RegExp(/\d+/g),
            },
            ({getFieldValue}) => ({
              validator(_, value) {
                const phoneNumber = parsePhoneNumber(
                  `${getFieldValue('countryCode')}${value}`,
                )
                const phoneType = phoneNumber?.getType()
                const requiredType = storedValue.phoneType

                if (
                  value &&
                  phoneType === requiredType &&
                  phoneNumber?.isValid()
                ) {
                  return Promise.resolve()
                }

                return Promise.reject(
                  t(
                    `SCREEN_DETAILS_PHONE_NUMBER_ERROR_INVALID_${requiredType}`,
                  ),
                )
              },
            }),
          ]}
        >
          <Input
            addonBefore={
              <Dropdown overlay={menuCountries()}>
                <div className="flagHolder">
                  <img
                    src={`/flags/${selectedCountry?.icon}`}
                    alt={selectedCountry?.name}
                  />
                  <div>{selectedCountry?.phoneCode}</div>
                  <div>
                    <DownOutlined />
                  </div>
                </div>
              </Dropdown>
            }
            size="large"
            disabled={isSaving}
            placeholder={t('SCREEN_DETAILS_PHONE_PLACEHOLDER', {
              phoneType: storedValue.phoneType,
            })}
          />
        </Form.Item>
        {storedValue.phoneType !== 'MOBILE' && (
          <Form.Item>
            <div className="highlightedBox">
              {t('SCREEN_DETAILS_PHONE_MESSAGE_LANDLINE')}
            </div>
          </Form.Item>
        )}
        <Features {...screen} />
      </Section>

      <Navigation form={form} />
    </Form>
  )
}

export default ScreenCustomerDetails
