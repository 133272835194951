/** @jsx jsx */
import {jsx} from '@emotion/core'

import Logo from '../../components/logo'
import Section from '../../components/section'
import {Checkbox, Form, Input, Typography} from 'antd'
import useScreen from '../../hooks/use-screen'
import Navigation from '../../components/navigation'
import SimpleTable from '../../components/simple-table'
import {navigate} from '@reach/router'
import ReCAPTCHA from 'react-google-recaptcha'
import {Fragment, useRef} from 'react'
import {RECAPTCHA_API_SITE_KEY} from '../../config'
import {activeRoutesSelector, configState, screenErrorState} from '../../state'
import {useRecoilValue} from 'recoil'
import {renderErrorMessage, truncateFileName} from '../../utils'
import moment from 'moment'
import useTranslate from '../../hooks/use-translate'
import {FileTextOutlined} from '@ant-design/icons'

const mapComponents = ({
  key = '',
  field = {},
  storedValue = {},
  t = (_: string) => {},
}: any): any => {
  switch (field.component) {
    case 'DatePicker':
      return (
        <Form.Item label={t(field.label)} key={key}>
          {storedValue?.[key]
            ? moment(storedValue?.[key]).format(
                field.dateFormat || 'YYYY/MM/DD',
              )
            : '-'}
        </Form.Item>
      )
    case 'TextArea': {
      return storedValue?.[key] ? (
        <Form.Item key={key} label={t(field.label)} className="formItem">
          <Input.TextArea autoSize readOnly value={storedValue?.[key]} />
        </Form.Item>
      ) : (
        ''
      )
    }
    case 'SimpleTable': {
      return (
        <SimpleTable
          storedValue={storedValue}
          key={key}
          data={field.config}
          readOnly={true}
        />
      )
    }
    case 'TextInput': {
      return storedValue?.[key] ? (
        <Form.Item key={key} className="formItem" label={t(field.label)}>
          {storedValue?.[key]}
        </Form.Item>
      ) : null
    }
    case 'RadioButtonsWithMore': {
      return storedValue?.[key] ? (
        <Form.Item key={key} className="formItem" label={t(field.label)}>
          {storedValue?.[key]}{' '}
          {Boolean(storedValue?.[`${key}_more`]) && (
            <span>({storedValue?.[`${key}_more`]})</span>
          )}
        </Form.Item>
      ) : null
    }
    default:
      return null
  }
}

const ScreenConfirm = () => {
  const {
    form,
    storedValue,
    client,

    updateState,
  } = useScreen()

  const {t} = useTranslate({
    clientData: {
      ...client,
    },
  })

  const screenError = useRecoilValue(screenErrorState)

  const activeRoutes = useRecoilValue(activeRoutesSelector)
  const config = useRecoilValue(configState)

  const recaptchaRef = useRef<ReCAPTCHA>(null)

  const {
    mobileNumber,
    personalNumber,
    firstName,
    lastName,
    startTime,
    files,
    referralType,
    withoutAppointment,
    otherInformationValue,
    otherInformation,
    organizationName,
    cityId,
    skipBooking,
  } = storedValue

  const bookedAppointmentFields = [
    {
      key: 'fullName',
      label: 'Namn',
      render: () => `${firstName} ${lastName}`,
    },
    {
      key: 'nin',
      label: t('SCREEN_DETAILS_NIN_LABEL'),
      render: () => personalNumber,
    },
    {
      key: 'phone',
      label: t('SCREEN_DETAILS_PHONE_LABEL'),
      render: () => `${mobileNumber}`,
    },
    otherInformation === 'yes' && {
      key: 'otherInformationValue',
      label: t('SCREEN_DETAILS_OTHER_INFORMATION'),
      render: () =>
        Array.isArray(otherInformationValue)
          ? otherInformationValue?.join(', ')
          : otherInformationValue,
    },
    !skipBooking &&
      !withoutAppointment &&
      Boolean(startTime) &&
      moment(startTime).isValid() && {
        key: 'startDate',
        label: t('SCREEN_LABEL_DATE'),
        render: () =>
          startTime ? `${moment(startTime).format('dddd D MMM')}` : '-',
      },
    !skipBooking &&
      !withoutAppointment &&
      Boolean(startTime) &&
      moment(startTime).isValid() && {
        key: 'startTime',
        label: t('SCREEN_LABEL_TIME'),
        render: () =>
          startTime ? `${moment(startTime).format('HH:mm')}` : '-',
      },
    {
      key: 'clinic',
      label: t('SCREEN_LABEL_CLINIC'),
      render: () => `${organizationName}, ${cityId}`,
    },
  ]

  const referralFields: any = client.pages?.['/referral'].form

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={storedValue}
      autoComplete="off"
    >
      <Section>
        <Logo />
      </Section>
      <Section>
        <div className="summaryBox">
          <div>
            <Typography.Title level={2}>
              {t('SCREEN_CONFIRM_DETAILS')}
              <div
                className="summaryBoxAction"
                onClick={() => navigate('/details')}
              >
                {t('GENERIC_EDIT')}
              </div>
            </Typography.Title>
          </div>
          {bookedAppointmentFields
            .filter((field: any) => Boolean(field))
            .map((field: any) => {
              return (
                <div className="summaryField" key={field.key}>
                  <div className="summaryLabel">{field.label}</div>
                  <div className="summaryValue">{field.render?.()}</div>
                </div>
              )
            })}
        </div>
      </Section>
      {Boolean(activeRoutes?.['/referral']) && (
        <Section>
          <div className="summaryBox">
            <div>
              <Typography.Title level={2}>
                {t('SCREEN_CONFIRM_REFERRAL')}
                <div
                  className="summaryBoxAction"
                  onClick={() => navigate('/referral')}
                >
                  {t('GENERIC_EDIT')}
                </div>
              </Typography.Title>
            </div>
            {referralType === 'form' && (
              <Fragment>
                {Object.keys(referralFields).map((key: string) =>
                  mapComponents({
                    key,
                    field: referralFields[key],
                    storedValue,
                    isSaving: true,
                    t,
                  }),
                )}
              </Fragment>
            )}

            {referralType === 'upload' && (
              <Fragment>
                {Boolean(files?.length) && (
                  <Form.Item className="formItem">
                    {storedValue?.files?.map((file: any) => {
                      return (
                        <div key={file.fileId} className="FileAttached">
                          <FileTextOutlined />{' '}
                          {truncateFileName(file.fileName, 30)}
                        </div>
                      )
                    })}
                  </Form.Item>
                )}
              </Fragment>
            )}
          </div>
        </Section>
      )}

      <Section>
        <div className="highlightedBox highlightedBoxNormal">
          <Form.Item
            name="agree"
            valuePropName="checked"
            rules={[{required: true, message: t('GENERIC_CONFIRM_ERROR')}]}
          >
            <Checkbox className="labelRequired">
              {t('GENERIC_CONFIRM_CHECKBOX')}
            </Checkbox>
          </Form.Item>
        </div>
      </Section>
      {screenError && (
        <Section>
          <div className="messageError">
            {renderErrorMessage(screenError, t)}
          </div>
        </Section>
      )}
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={RECAPTCHA_API_SITE_KEY}
        onChange={token => {
          updateState(currentState => ({
            ...currentState,
            recaptchaToken: token,
          }))
        }}
        hl={config.reCAPTCHADefaultLang}
      />
      <Navigation form={form} />
    </Form>
  )
}

export default ScreenConfirm
