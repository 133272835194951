export const APP_ENVIRONMENT = process?.env?.REACT_APP_ENV || 'development'

export const IS_DEV = APP_ENVIRONMENT === 'development'

export const STORAGE = window.sessionStorage

export const API_SERVICE_URL = process.env.REACT_APP_API_SERVICE_URL
export const PDF_SERVICE_URL = process.env.REACT_APP_PDF_SERVICE_URL

export const FACEBOOK_PIXEL_ID = process.env.REACT_APP_FACEBOOK_PIXEL_ID

export const GDPR_COOKIE_NAME = 'KindGDPRCompliance'

export const LANG_DEFAULT = 'se'
export const LANG_DEFAULT_SHORT = 'se'

export const FILE_UPLOAD_SIZE_LIMIT = 10
export const FILE_UPLOAD_SIZE_LIMIT_TEXT = '10MB'

export const RECAPTCHA_API_SITE_KEY =
  process.env.REACT_APP_RECAPTCHA_API_SITE_KEY || ''
