import {css} from '@emotion/core'
import theme from '../../theme'

export const section = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 570px;
  margin-bottom: 30px;
`

export const sectionWide = css`
  max-width: 100%;
  min-width: 100%;

  > img.headImage {
    max-width: 100%;
    border-radius: ${theme.BorderRadiusBase};
  }
`
