/** @jsx jsx */
import {jsx} from '@emotion/core'

import Logo from '../../components/logo'
import Section from '../../components/section'
import {Form} from 'antd'
import useScreen from '../../hooks/use-screen'
import Navigation from '../../components/navigation'
import useTranslate from '../../hooks/use-translate'
import InputReferenceCode from '../../components/input-reference-code'
import {useRecoilValue} from 'recoil'
import {clientState} from 'src/state'

const ScreenReferenceCode = () => {
  const {form, storedValue} = useScreen()

  const client = useRecoilValue(clientState)
  console.log('xx:client', client)

  const {t} = useTranslate({
    clientData: {
      ...client,
    },
  })

  return (
    <Form
      autoComplete="off"
      form={form}
      layout="vertical"
      initialValues={storedValue}
    >
      <Section>
        <Logo />
      </Section>
      <Section>
        <div>{t('SCREEN_REFERENCE_CODE_LINE_1')}</div>
      </Section>
      <Section>
        <InputReferenceCode />
      </Section>
      <Navigation form={form} />
    </Form>
  )
}

export default ScreenReferenceCode
