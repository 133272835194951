/** @jsx jsx */
import {jsx} from '@emotion/core'
import Section from '../section'

const HeadImage = ({src = ''}: any) => {
  return (
    <Section type="wide">
      <img alt="Booking by KIND" src={src} className="headImage" />
    </Section>
  )
}

export default HeadImage
