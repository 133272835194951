/** @jsx jsx */
import {jsx} from '@emotion/core'
import * as style from './page.style'
import {Grid} from 'antd'

const Page = ({children}: any) => {
  const {useBreakpoint} = Grid

  const screens = useBreakpoint()

  return (
    <div css={[style.page, screens.xs && style.pageMobile]}>{children}</div>
  )
}

export default Page
