import {css} from '@emotion/core'
import theme from '../../theme'

export const fileUploader = css`
  margin-bottom: 15px;
`

export const fileUploaderError = css`
  margin-bottom: 15px;
`

export const fileUploaderList = css`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const fileUploaderListItem = css`
  display: flex;
  flex: 1;
  background-color: ${theme.ClassicComponentBackground};
  border-radius: ${theme.BorderRadiusBase};
  padding: 5px 10px;
  margin-bottom: 10px;

  > div:first-of-type {
    flex: 1;
  }

  > div:last-of-type {
  }
`
