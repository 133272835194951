const PrimaryColor = '#B2DCCA'
const PrimaryBorderColor = '#000000'
const DefaultColor = '#000000'
const DefaultBorderColor = '#000000'
const BorderLineColor = '#d9d9d9'
const ClassicComponentBackground = '#f0f0f0'
const PrimaryHighlightColor = '#e6f7ff'
const SecondaryColor = '#521d49'
const NavigationColor = '#000000'
const LinkColor = '#1890ff'

const HighlightedColor = '#fff5d4'
const TextColor = '#000000'
const TableColumnFrozen = '#e8f0f1'

const BackgroundColor = '#f4f4f4';
const WhiteColor = '#ffffff'
const RedColor = '#f5222d'
const GreenColor = '#52c41a'
const Transparent = 'transparent'
const GrayColor = '#a3b9bd'
const BlackColor = '#1f1f1f'
const LightBlackColor = '#262626'
const OrangeColor = '#fa8c16'
const YellowColor = '#fadb14'
const LighterYellowColor = '#fffb8f'
const LightYellowColor = '#ffffb8'
const LighterGrayColor = '#f7f7f7';
const LightGrayColor = '#e5e5e5';
const LimeColor = '#389e0d'
const BlueColor = '#1B6AC9'
const LighterBlueColor = '#e6f7ff'
const BrandColor = '#521d49'

const BorderRadiusBase = '5px'
const BorderWidthBase = '2px'

const PlaceholderIconColor = 'rgba(0,0,0,.25)'
const ButtonOnDarkColor = 'rgba(255,255,255,.25)'

module.exports = {
  PrimaryColor, 
  PrimaryBorderColor, 
  DefaultColor, 
  DefaultBorderColor, 
  BorderLineColor, 
  ClassicComponentBackground, 
  PrimaryHighlightColor,
  SecondaryColor,
  NavigationColor,
  LinkColor,

  HighlightedColor,
  TextColor,
  TableColumnFrozen,
  
  BackgroundColor,
  WhiteColor,
  RedColor,
  GreenColor,
  Transparent,
  GrayColor,
  BlackColor,
  LightBlackColor,
  OrangeColor,
  YellowColor,
  LighterYellowColor,
  LightYellowColor,
  LighterGrayColor,
  LightGrayColor,
  LimeColor,
  BlueColor,
  LighterBlueColor,
  BrandColor,

  BorderRadiusBase,
  BorderWidthBase,

  PlaceholderIconColor,
  ButtonOnDarkColor
}