/** @jsx jsx */
import {jsx} from '@emotion/core'
import {Col, Form, Input, Row} from 'antd'
import {Fragment} from 'react'

const isEditableCell = (key: string, columns: any) =>
  !columns?.[key]?.hasOwnProperty('editable') ||
  columns?.[key]?.editable === true

const SimpleTable = ({
  storedValue = {},
  data = {},
  isSaving = false,
  readOnly = false,
}: any) => {
  return (
    <Form.Item className="formItem uiTable">
      {Boolean(Object.keys(data)?.length) ? (
        <Fragment>
          <Row>
            {data?.columns?.map((column: any) => {
              return (
                <Col key={column.key} className="frozen">
                  {column.name}
                </Col>
              )
            })}
          </Row>
          {data?.rows?.map((row: any) => {
            return (
              <Row key={row.key}>
                {data?.columns?.map((column: any) => {
                  return (
                    <Fragment key={column.key}>
                      {isEditableCell(column.key, row.columns) ? (
                        <Col>
                          <Form.Item
                            name={`${data.key}.${row.key}.${column.key}`}
                            className="cell"
                            rules={row.columns?.[column.key]?.rules}
                            hasFeedback={false}
                          >
                            {readOnly ? (
                              <div className="readOnlyValue">
                                {
                                  storedValue[
                                    `${data.key}.${row.key}.${column.key}`
                                  ]
                                }
                              </div>
                            ) : (
                              <Input
                                size="large"
                                bordered={true}
                                disabled={isSaving}
                                placeholder="0"
                              />
                            )}
                          </Form.Item>
                        </Col>
                      ) : (
                        <Col className="frozen">{row.name}</Col>
                      )}
                    </Fragment>
                  )
                })}
              </Row>
            )
          })}
        </Fragment>
      ) : (
        'No data'
      )}
    </Form.Item>
  )
}

export default SimpleTable
