/** @jsx jsx */
import {jsx} from '@emotion/core'

import {Fragment} from 'react'
import Logo from '../../components/logo'
import Section from '../../components/section'

const ScreenEmpty = () => {
  return (
    <Fragment>
      <Section>
        <Logo />
      </Section>
      <Section>
        <div>Empty page</div>
      </Section>
    </Fragment>
  )
}

export default ScreenEmpty
