import {css} from '@emotion/core'
import theme from '../../theme'

export const defaultButtonStyle = css`
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: ${theme.WhiteColor}!important;

  &.ant-btn-lg {
    height: 50px;
  }

  &.ant-btn-min {
    min-width: 250px;
  }
`

export const iconStyle = css`
  font-size: 12px;
`
