/** @jsx jsx */
import {jsx} from '@emotion/core'

import {Form, Input} from 'antd'
import useScreen from '../../hooks/use-screen'
import {useRecoilValue} from 'recoil'
import {clientState, screenErrorState} from '../../state'
import {renderErrorMessage} from '../../utils'
import InlineErrorMessage from '../../components/inline-error-message'
import useTranslate from '../../hooks/use-translate'
import {validateReferenceCode} from '../../api/backend-client'

const InputReferenceCode = ({...props}) => {
  const {setScreenError, isSaving} = useScreen()

  const screenError = useRecoilValue(screenErrorState)
  const client = useRecoilValue(clientState)

  const {t} = useTranslate({
    clientData: {
      ...client,
    },
  })

  return (
    <Form.Item
      name="referenceCode"
      className="formItem"
      label={t('REFERENCE_CODE_LABEL')}
      help={
        screenError ? (
          <InlineErrorMessage message={renderErrorMessage(screenError, t)} />
        ) : (
          t('FIELD_REFERENCE_CODE_HELP')
        )
      }
      rules={[
        {required: true},
        () => ({
          validateTrigger: 'onSubmit',
          validator(_, value) {
            if (!value) {
              return Promise.reject(new Error('No value'))
            }
            setScreenError('')
            return validateReferenceCode(value)
              .then(() => {
                return Promise.resolve()
              })
              .catch(() => {
                setScreenError('1000')
                return Promise.reject(new Error('Validation error'))
              })
          },
        }),
      ]}
      {...props}
    >
      <Input size="large" autoFocus disabled={isSaving} />
    </Form.Item>
  )
}

export default InputReferenceCode
