import {BookingModel} from '../types'
import client from './api-client'

function createBooking({
  referenceCode,
  phoneNumber,
  nin,
  firstName,
  lastName,
  organizationId,
  startTime,
  promoCode,
  form,
  testType,
  files,
  recaptchaToken,
}: BookingModel) {
  return client(`calendarSlots`, {
    method: 'POST',
    body: {
      referenceCode,
      phoneNumber,
      nin,
      firstName,
      lastName,
      organizationId,
      startTime,
      promoCode,
      testType,
      files,
      form: {formData: JSON.stringify(form)},
    },
    headers: {
      'g-recaptcha-response': recaptchaToken || '',
    },
  }).catch(error => {
    throw error
  })
}

export {createBooking}
